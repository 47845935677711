/* ==============
    Navigation Style
   ============== */

.navigation {
  @include flexbox(center);
  z-index: 100;
  position: fixed;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  background-color: $themeLightColor;

  &-container {
    margin: 0 $sideSpaceMobile;
    min-height: $navHeight;
    width: $maxSectionWidth;
  }

  &-top {
    @include flexbox(space-between);
  }

  &-title {
    @include logo;
    font-size: 2rem;
  }

  a {
    color: black;
    margin: 0;
  }

  &-contact {
    color: white;
  }

  &-menu {
    @include flexbox(flex-end);
  }

  ul {
    @include flexbox(space-around, center);
    right: 0;
    list-style: none;
    font-size: 1rem;

    li {
      @include flexbox(center, center);
      @include size(110px, 50px);
      overflow: hidden;
      text-align: center;

      a {
        color: white;
        text-decoration: none;
        position: relative;

        &::after {
          content: "";
          @include size(100%, 100%);
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-100%);
          transition: transform 0.5s ease;
        }

        &:hover {
          color: $themeDarkColor;
        }
      }
    }
  }
}

.burger {
  display: none;
  position: absolute;
  top: 50px;
  cursor: pointer;

  div {
    @include size(25px, 3px);
    background-color: white;
    margin: 5px;
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 420px) {
  .navigation {
    &-title {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .navigation-top {
    flex-direction: column;
  }

  .navigation-links {
    @include flexbox(space-around, center, column);
    @include size(200px, calc(100vh - #{$navHeight}));
    background-color: $themeLightColor;
    position: absolute;
    left: 100%;
    transform: translateX(0%);
    transition: transform 0.5s ease-in;
    top: $navHeight;

    li {
      opacity: 0;
    }
  }

  .burger {
    display: block;
  }
}

.nav-active {
  transform: translateX(-100%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle {
  .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .line2 {
    opacity: 0;
  }

  .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

a.active::after {
  transform: translateX(0%);
}