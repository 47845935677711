$themeLightColor: #ff983f;
$themeColor: #FF6600;
$themeDarkColor: #c43300;
$subLightColor:#62727b;
$subColor:#37474F;
$subDarkColor:#102027;

$navHeight: 100px;
$sideSpace: 10%;
$sideSpaceMobile: 5%;
$footerHeight: 20px;
$maxSectionWidth: 1024px;