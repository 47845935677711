h1 {
  display: block;
  font-size: 2em;
  // margin-top: 0.67em;
  // margin-bottom: 0.67em;
  // margin-left: 0;
  // margin-right: 0;
  line-height: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h2 {
  display: block;
  font-size: 1.5em;
  // margin-top: 0.83em;
  // margin-bottom: 0.83em;
  // margin-left: 0;
  // margin-right: 0;
  line-height: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h3 {
  display: block;
  font-size: 1.17em;
  // margin-top: 1em;
  // margin-bottom: 1em;
  // margin-left: 0;
  // margin-right: 0;
  line-height: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h4 {
  display: block;
  font-size: 1em;
  // margin-top: 1.33em;
  // margin-bottom: 1.33em;
  // margin-left: 0;
  // margin-right: 0;
  line-height: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h5 {
  display: block;
  font-size: .83em;
  // margin-top: 1.67em;
  // margin-bottom: 1.67em;
  // margin-left: 0;
  // margin-right: 0;
  line-height: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

h6 {
  display: block;
  font-size: .67em;
  // margin-top: 2.33em;
  // margin-bottom: 2.33em;
  // margin-left: 0;
  // margin-right: 0;
  line-height: normal;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

dl {
  display: block;
  // margin-top: 1em;
  // margin-bottom: 1em;
  // margin-left: 0;
  // margin-right: 0;
  margin: 0;
  padding: 0;
}

ol {
  display: block;
  list-style-type: decimal;
  // margin-top: 1em;
  // margin-bottom: 1em;
  // margin-left: 0;
  // margin-right: 0;
  // padding-left: 40px;
  margin: 0;
  padding: 0;
}

ul {
  display: block;
  list-style-type: disc;
  // margin-top: 1em;
  // margin-bottom: 1em;
  // margin-left: 0;
  // margin-right: 0;
  // padding-left: 40px;
  margin: 0;
  padding: 0;
}

p {
  display: block;
  // margin-top: 1em;
  // margin-bottom: 1em;
  // margin-left: 0;
  // margin-right: 0;
  margin: 0;
  padding: 0;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

a {
  text-decoration: none;
}

.App {
  min-height: 100vh;
}