/*----- LineUp -----*/
.lineup {
  .lineup-subtitle {
    background-color: lightgray;
    padding: 0.75rem;
  }

  .table-header {
    display: flex;
    font-size: .8rem;
    padding: 0.5rem;
    color: $subLightColor;
    width: 100%;
    border-bottom: 1px solid #cecece;
  }

  .table-row {
    border-bottom: 1px solid #cecece;
    width: 100%;
    padding: 0.5rem;
    display: flex;
  }

  .lineup-card {
    display: flex;
    margin: 3px 0;
  }
}

@media all and (max-width: 640px) {
  .lineup {
    padding: 100px 0;

    .table-header {
      display: none;
    }

    .table-row {
      border: 1px solid $subLightColor;
      display: block;
    }

    p {
      margin-bottom: 3px;
      border-bottom: 1px solid #cecece;
    }

    .col {
      width: 100%;
      display: flex;

      &:before {
        color: $subLightColor;
        padding-right: 10px;
        width: 100px;
        font-size: .8rem;
        content: attr(data-label);
      }
    }
  }
}

/*----- Details -----*/
.details {
  color: $subColor;
  min-height: calc(100vh - #{$footerHeight});
}

@media screen and (max-width:768px) {
  .details {
    padding: 100px 0;
  }
}

.detail-card {
  display: flex;
  justify-content: space-between;

  .detail-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    border-bottom: 1px dashed;
  }

  .detail-model {
    margin: 1rem 0;
  }

  .detail-images {
    width: calc(100% - 400px);
  }

  .detail-image {
    width: 100%;
  }

  .detail-contents {
    width: 400px;
    padding-left: 2rem;
  }

  .detail-title {
    font-size: 0.75rem;
  }
}

@media screen and (max-width:1000px) {
  .detail-card {
    flex-direction: column;

    .detail-images {
      width: 100%;
    }

    .detail-contents {
      width: 100%;
      padding: 0;
    }
  }

  .no-image {
    display: none;
  }
}

@media screen and (max-width:500px) {
  .detail-card {
    .each-detail {
      display: flex;
    }
  }
}

.no-image {
  text-align: center;
  font-size: 2rem;
  flex-grow: 1;
  position: relative;
  border: 1px solid $subLightColor;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
}