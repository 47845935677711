/*----- News -----*/
.news {
  .news-container {
    display: flex;
  }

  .news-sidebar {
    width: 300px;
    border-right: 1px dashed;
  }

  .news-sidebar-date {
    font-size: 0.75rem;
  }

  .news-sidebar-title {
    font-size: 0.9rem;
  }

  .news-contents {
    margin-left: 2rem;
    margin-bottom: 2rem;
    width: calc(100% - 300px);
  }

  .news-title {
    padding: 1rem 0;
  }

  .news-content {
    white-space: pre-wrap;
  }

  .news-images {
    width: 100%;
    display: flex;
    justify-content: center;

    .news-image {
      height: 400px;
    }
  }
}

@media screen and (max-width:1000px) {
  .news {
    .news-sidebar {
      width: 100%;
      border-right: none;
      border-top: 1px dashed;
    }

    .news-container {
      flex-direction: column-reverse;
    }

    .news-contents {
      margin-left: 0;
      width: 100%;

    }
  }
}