@for $i from 1 through 10 {
  .ma#{$i * 5} {
    margin:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .mb#{$i * 5} {
    margin-bottom:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .mt#{$i * 5} {
    margin-top:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .ml#{$i * 5} {
    margin-left:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .mr#{$i * 5} {
    margin-right:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .mx#{$i * 5} {
    margin:0 #{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .my#{$i * 5} {
    margin:#{$i * 5}px 0;
  }
}

@for $i from 1 through 10 {
  .pa#{$i * 5} {
    padding:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .pb#{$i * 5} {
    padding-bottom:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .pt#{$i * 5} {
    padding-top:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .pl#{$i * 5} {
    padding-left:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .pr#{$i * 5} {
    padding-right:#{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .px#{$i * 5} {
    padding:0 #{$i * 5}px;
  }
}

@for $i from 1 through 10 {
  .py#{$i * 5} {
    padding:#{$i * 5}px 0;
  }
}