.cad {
  .cad-images {}

  img {
    width: 100%;
    border: 1px solid $subColor;
    margin: 1rem;
    max-width: 270px;
  }
}

.skybox {
  color: $subColor;
  min-height: calc(100vh - #{$footerHeight});

  .subtitle {
    border-bottom: 1px solid $themeDarkColor;
  }

  .detail-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    border-bottom: 1px dashed;
  }

  .detail-model {
    margin: 1rem 0;
  }

  .detail-title {
    font-size: 0.75rem;
  }

  .available-type {
    list-style-type: none;
  }
}

.image-gallery-slide img {
  max-height: 400px !important;
}