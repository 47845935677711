/*----- Contact -----*/
.contact {
  iframe {
    padding-top: 1rem;
    height: 400px;
    width: 100%;
    max-width: $maxSectionWidth;
  }
}

@media screen and (max-width:650px) {
  .contact {
    iframe {
      height: 300px;
      padding: 0;
    }
  }
}